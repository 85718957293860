define("discourse/plugins/chat/discourse/components/chat/list/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "ember-modifier", "discourse/components/conditional-loading-spinner", "discourse/lib/is-element-in-viewport", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse/plugins/chat/discourse/components/chat/list/empty-state", "discourse/plugins/chat/discourse/components/chat/list/item", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _object, _emberModifier, _conditionalLoadingSpinner, _isElementInViewport, _environment, _debounce, _emptyState, _item, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let List = _exports.default = (_class = (_class2 = class List extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "loadMore", (0, _emberModifier.modifier)(element1 => {
        this.intersectionObserver = new IntersectionObserver(this.loadCollection);
        this.intersectionObserver.observe(element1);
        return () => {
          this.intersectionObserver.disconnect();
        };
      }));
      _defineProperty(this, "fill", (0, _emberModifier.modifier)(element1 => {
        this.resizeObserver = new ResizeObserver(() => {
          if ((0, _isElementInViewport.default)(element1)) {
            this.loadCollection();
          }
        });
        this.resizeObserver.observe(element1);
        return () => {
          this.resizeObserver.disconnect();
        };
      }));
    }
    get itemComponent() {
      return this.args.itemComponent ?? _item.default;
    }
    loadCollection() {
      (0, _debounce.default)(this, this.debouncedLoadCollection, _environment.INPUT_DELAY);
    }
    async debouncedLoadCollection() {
      await this.args.collection.load({
        limit: 10
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-list">
        <div {{this.fill}} ...attributes>
          {{#each @collection.items as |item|}}
            {{yield (hash Item=(component this.itemComponent item=item))}}
          {{else}}
            {{#if @collection.fetchedOnce}}
              {{yield (hash EmptyState=EmptyState)}}
            {{/if}}
          {{/each}}
        </div>
  
        <div {{this.loadMore}}>
          <br />
        </div>
  
        <ConditionalLoadingSpinner @condition={{@collection.loading}} />
      </div>
    
  */
  {
    "id": "5Vd4L13R",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-list\"],[12],[1,\"\\n      \"],[11,0],[17,1],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2,[\"items\"]]],null]],null],null,[[[1,\"          \"],[18,4,[[28,[32,0],null,[[\"Item\"],[[50,[30,0,[\"itemComponent\"]],0,null,[[\"item\"],[[30,3]]]]]]]]],[1,\"\\n\"]],[3]],[[[41,[30,2,[\"fetchedOnce\"]],[[[1,\"            \"],[18,4,[[28,[32,0],null,[[\"EmptyState\"],[[32,1]]]]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\\n      \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@condition\"],[[30,2,[\"loading\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@collection\",\"item\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"component\",\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/index.js",
    "scope": () => [_helper.hash, _emptyState.default, _conditionalLoadingSpinner.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "loadCollection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadCollection"), _class.prototype)), _class);
});