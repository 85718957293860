define("discourse/plugins/chat/discourse/components/channel-icon/index", ["exports", "@glimmer/component", "@ember/template", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _template, _dIcon, _chatUserAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatChannelIcon extends _component.default {
    get firstUser() {
      return this.args.channel.chatable.users[0];
    }
    get groupDirectMessage() {
      return this.args.channel.isDirectMessageChannel && this.args.channel.chatable.group;
    }
    get channelColorStyle() {
      return (0, _template.htmlSafe)(`color: #${this.args.channel.chatable.color}`);
    }
  }
  _exports.default = ChatChannelIcon;
  _class = ChatChannelIcon;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @channel.isDirectMessageChannel}}
        <div class="chat-channel-icon">
          {{#if this.groupDirectMessage}}
            <span class="chat-channel-icon --users-count">
              {{@channel.membershipsCount}}
            </span>
          {{else}}
            <div class="chat-channel-icon --avatar">
              <ChatUserAvatar @user={{this.firstUser}} @interactive={{false}} />
            </div>
          {{/if}}
        </div>
      {{else if @channel.isCategoryChannel}}
        <div class="chat-channel-icon">
          <span
            class="chat-channel-icon --category-badge"
            style={{this.channelColorStyle}}
          >
            {{icon "d-chat"}}
            {{#if @channel.chatable.read_restricted}}
              {{icon "lock" class="chat-channel-icon__restricted-category-icon"}}
            {{/if}}
          </span>
        </div>
      {{/if}}
    
  */
  {
    "id": "XjRiefTp",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"isDirectMessageChannel\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-icon\"],[12],[1,\"\\n\"],[41,[30,0,[\"groupDirectMessage\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-channel-icon --users-count\"],[12],[1,\"\\n            \"],[1,[30,1,[\"membershipsCount\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"chat-channel-icon --avatar\"],[12],[1,\"\\n            \"],[8,[32,0],null,[[\"@user\",\"@interactive\"],[[30,0,[\"firstUser\"]],false]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"isCategoryChannel\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-icon\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-channel-icon --category-badge\"],[15,5,[30,0,[\"channelColorStyle\"]]],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"d-chat\"],null]],[1,\"\\n\"],[41,[30,1,[\"chatable\",\"read_restricted\"]],[[[1,\"            \"],[1,[28,[32,1],[\"lock\"],[[\"class\"],[\"chat-channel-icon__restricted-category-icon\"]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/channel-icon/index.js",
    "scope": () => [_chatUserAvatar.default, _dIcon.default],
    "isStrictMode": true
  }), _class);
});