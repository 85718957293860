define("discourse/plugins/chat/discourse/components/chat-upload", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "discourse/lib/uploads", "truth-helpers/helpers/eq", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _modifier, _object, _service, _template, _uploads, _eq, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatUpload = _exports.default = (_class = (_class2 = class ChatUpload extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "loaded", _descriptor2, this);
      _defineProperty(this, "IMAGE_TYPE", "image");
      _defineProperty(this, "VIDEO_TYPE", "video");
      _defineProperty(this, "AUDIO_TYPE", "audio");
      _defineProperty(this, "ATTACHMENT_TYPE", "attachment");
    }
    get type() {
      if ((0, _uploads.isImage)(this.args.upload.original_filename)) {
        return this.IMAGE_TYPE;
      }
      if ((0, _uploads.isVideo)(this.args.upload.original_filename)) {
        return this.VIDEO_TYPE;
      }
      if ((0, _uploads.isAudio)(this.args.upload.original_filename)) {
        return this.AUDIO_TYPE;
      }
      return this.ATTACHMENT_TYPE;
    }
    get size() {
      const width1 = this.args.upload.width;
      const height1 = this.args.upload.height;
      const ratio1 = Math.min(this.siteSettings.max_image_width / width1, this.siteSettings.max_image_height / height1);
      return {
        width: width1 * ratio1,
        height: height1 * ratio1
      };
    }
    get imageUrl() {
      return this.args.upload.thumbnail?.url ?? this.args.upload.url;
    }
    get imageStyle() {
      if (this.args.upload.dominant_color && !this.loaded) {
        return (0, _template.htmlSafe)(`background-color: #${this.args.upload.dominant_color};`);
      }
    }
    imageLoaded() {
      this.loaded = true;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if (eq this.type this.IMAGE_TYPE)}}
        <img
          class="chat-img-upload"
          data-orig-src={{@upload.short_url}}
          data-large-src={{@upload.url}}
          height={{this.size.height}}
          width={{this.size.width}}
          src={{this.imageUrl}}
          style={{this.imageStyle}}
          loading="lazy"
          tabindex="0"
          data-dominant-color={{@upload.dominant_color}}
          {{on "load" this.imageLoaded}}
        />
      {{else if (eq this.type this.VIDEO_TYPE)}}
        <video class="chat-video-upload" preload="metadata" height="150" controls>
          <source src={{@upload.url}} />
        </video>
      {{else if (eq this.type this.AUDIO_TYPE)}}
        <audio class="chat-audio-upload" preload="metadata" controls>
          <source src={{@upload.url}} />
        </audio>
      {{else}}
        <a
          class="chat-other-upload"
          data-orig-href={{@upload.short_url}}
          href={{@upload.url}}
        >
          {{@upload.original_filename}}
        </a>
      {{/if}}
    
  */
  {
    "id": "3cGqBSgP",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"IMAGE_TYPE\"]]],null],[[[1,\"      \"],[11,\"img\"],[24,0,\"chat-img-upload\"],[16,\"data-orig-src\",[30,1,[\"short_url\"]]],[16,\"data-large-src\",[30,1,[\"url\"]]],[16,\"height\",[30,0,[\"size\",\"height\"]]],[16,\"width\",[30,0,[\"size\",\"width\"]]],[16,\"src\",[30,0,[\"imageUrl\"]]],[16,5,[30,0,[\"imageStyle\"]]],[24,\"loading\",\"lazy\"],[24,\"tabindex\",\"0\"],[16,\"data-dominant-color\",[30,1,[\"dominant_color\"]]],[4,[32,1],[\"load\",[30,0,[\"imageLoaded\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"VIDEO_TYPE\"]]],null],[[[1,\"      \"],[10,\"video\"],[14,0,\"chat-video-upload\"],[14,\"preload\",\"metadata\"],[14,\"height\",\"150\"],[14,\"controls\",\"\"],[12],[1,\"\\n        \"],[10,\"source\"],[15,\"src\",[30,1,[\"url\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"AUDIO_TYPE\"]]],null],[[[1,\"      \"],[10,\"audio\"],[14,0,\"chat-audio-upload\"],[14,\"preload\",\"metadata\"],[14,\"controls\",\"\"],[12],[1,\"\\n        \"],[10,\"source\"],[15,\"src\",[30,1,[\"url\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,3],[14,0,\"chat-other-upload\"],[15,\"data-orig-href\",[30,1,[\"short_url\"]]],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n        \"],[1,[30,1,[\"original_filename\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]]],[1,\"  \"]],[\"@upload\"],false,[\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-upload.js",
    "scope": () => [_eq.default, _modifier.on],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "imageLoaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "imageLoaded"), _class.prototype)), _class);
});