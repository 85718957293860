define("discourse/plugins/chat/discourse/components/chat-notices", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat-notice", "discourse/plugins/chat/discourse/components/chat-retention-reminder", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _chatNotice, _chatRetentionReminder, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatNotices = _exports.default = (_dec = (0, _service.inject)("chat-channel-notices-manager"), (_class = (_class2 = class ChatNotices extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "noticesManager", _descriptor, this);
    }
    get noticesForChannel() {
      return this.noticesManager.notices.filter(notice1 => notice1.channelId === this.args.channel.id);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-notices">
        <ChatRetentionReminder @channel={{@channel}} />
  
        {{#each this.noticesForChannel as |notice|}}
          <ChatNotice @notice={{notice}} @channel={{@channel}} />
        {{/each}}
      </div>
    
  */
  {
    "id": "4mVfK5q6",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"noticesForChannel\"]]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@notice\",\"@channel\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"notice\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notices.js",
    "scope": () => [_chatRetentionReminder.default, _chatNotice.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "noticesManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});