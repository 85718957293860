define("discourse/plugins/chat/discourse/components/chat/message-creator/members-count", ["exports", "@glimmer/component", "discourse/helpers/concat-class", "discourse-i18n", "truth-helpers/helpers/gte", "@ember/template-factory", "@ember/component"], function (_exports, _component, _concatClass, _discourseI18n, _gte, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class MembersCount extends _component.default {
    get countLabel() {
      return _discourseI18n.default.t("chat.direct_message_creator.members_counter", {
        count: this.args.count,
        max: this.args.max
      });
    }
  }
  _exports.default = MembersCount;
  _class = MembersCount;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass
          "chat-message-creator__members-count"
          (if (gte @count @max) "-reached-limit")
        }}
      >
        {{this.countLabel}}
      </div>
    
  */
  {
    "id": "wbbPcP3A",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-message-creator__members-count\",[52,[28,[32,1],[[30,1],[30,2]],null],\"-reached-limit\"]],null]],[12],[1,\"\\n      \"],[1,[30,0,[\"countLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@count\",\"@max\"],false,[\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members-count.js",
    "scope": () => [_concatClass.default, _gte.default],
    "isStrictMode": true
  }), _class);
});